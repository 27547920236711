

















































































import {isNull} from '@labor-digital/helferlein';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {map} from '@labor-digital/helferlein/lib/Lists/map';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {Collection} from '@labor-digital/typo3-vue-framework/lib/Core/JsonApi/IdeHelper';
import {AppStoreKeys} from '../../../../AppStoreKeys';
import LocationTeaser from '../../../../Component/Branch/BranchTeaser/BranchTeaser.vue';
import Contact from '../../../../Component/Forms/Contact/Contact.vue';
import JobButtons from '../../../../Component/Job/JobButtons/JobButtons.vue';
import MagazineListItem from '../../../../Component/Magazine/ListItem/MagazineListItem.vue';
import Btn from '../../../../Component/Misc/Btn/Btn.vue';
import ImageSlider from '../../../../Component/Misc/Image/ImageSlider/ImageSlider.vue';
import LinkWrap from '../../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';
import NewsAndEventList from '../../../../Component/NewsAndEvent/List/NewsAndEventList.vue';
import {Branch} from '../../../../Interface/Branch';
import OpenGraphMixin from '../../../../Mixin/OpenGraphMixin';
import {BranchAndGeoLocationService} from '../../../../Service/BranchAndGeoLocationService';
import {InitiativeApplicationService} from '../../../../Service/InitiativeApplicationService';
import {JsonLdService} from '../../../../Service/JsonLdService';
import {MenuService} from '../../../../Service/MenuService';
import Headline from '../../Headline/Headline.vue';
import JobLatest from '../../JobLatest/JobLatest.vue';

export default {
    name: 'BranchSearchDetail',
    components: {Headline, Contact, JobLatest, NewsAndEventList, LinkWrap, ImageSlider, LocationTeaser, MagazineListItem, Btn, JobButtons},
    mixins: [OpenGraphMixin],
    props: {
        context: null as ContentElementContext
    },
    data()
    {
        return {
            magazineArticlesResult: []
        };
    },
    methods: {
        goTo(ref)
        {
            this.$refs[ref].scrollIntoView({behavior: 'smooth'});
        },
        onListClick()
        {
            InitiativeApplicationService.setSearchParameter(this.currentBranch);
        }
    },
    computed: {
        pageContext(): PageContext
        {
            return this.context.pageContext;
        },
        currentBranch(): Branch
        {
            return this.context.initialState.getAll();
        },
        currentBranchCode(): Branch
        {
            return isNull(this.currentBranch) ? null : this.currentBranch.code;
        },
        magazineArticles(): Array<PlainObject>
        {
            return this.magazineArticlesResult;
        },
        isForCompanies(): boolean
        {
            return MenuService.isForCompanies();
        },
        metaDescription()
        {

            if (MenuService.isForCompanies()) {
                return this.$t('branch.detail.meta.company', [this.currentBranch.city]);
            }

            return this.$t('branch.detail.meta.applicants', [this.currentBranch.city, this.currentBranch.city]);
        },
        ogDescription(): string
        {
            return this.getOgDescription(this.metaDescription);
        },
        ogTitle(): string
        {
            return this.isForCompanies ? this.getOgTitle(this.$t('branch.detail.title.company', [this.currentBranch.city]))
                : this.getOgTitle(this.$t('branch.detail.title.applicants', [this.currentBranch.city]));
        }
    },
    metaInfo()
    {
        return {
            title: this.ogTitle,
            script: [
                JsonLdService.makeBreadcrumb(this.currentBranch.name),
                JsonLdService.makeEmploymentAgency(this.currentBranch)
            ],
            link: [
                {'rel': 'canonical', 'href': this.currentBranch.urlForApplicants, 'vmid': 't3-canonical'}
            ],
            meta: this.metaTags.concat([
                {'name': 'description', 'content': this.ogDescription, 'vmid': 't3-description'},
                {'name': 'robots', 'content': this.isForCompanies ? 'noindex,follow' : 'index,follow'}
            ])
        };
    },
    created(): void
    {
        if (MenuService.isForCompanies()) {
            this.context.store.set(AppStoreKeys.PAGE_HEADLINE_OVERRIDE, 'persona service ' + this.currentBranch.name);
        } else {
            this.context.store.set(AppStoreKeys.PAGE_HEADLINE_OVERRIDE,
                'persona service ' + this.currentBranch.name + ': ' + this.$t('branchSearch.detail.applicants.headline'));
        }
    },
    mounted(): void
    {
        // Set the selected branch if we don't have one yet
        if (BranchAndGeoLocationService.getCurrentBranch() === null) {
            BranchAndGeoLocationService.setCurrentBranch(this.currentBranch);
        }

        this.context.resourceApi
            .getCollection('magazineArticle', {
                filter: {
                    branch: this.currentBranch.id
                },
                include: ['teaserImages'],
                page: {
                    size: 3
                }
            })
            .then((response: Collection) => {
                this.magazineArticlesResult = map(response.getAll(), (v) => v.getAll());
            });
    }
};
