var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "branchDetail" },
    [
      _c("div", { staticClass: "branchDetail__teaser" }, [
        _c(
          "div",
          { staticClass: "branchDetail__teaserInner" },
          [
            _c("location-teaser", {
              attrs: {
                context: _vm.context,
                "is-horizontal": "",
                branch: _vm.currentBranch,
              },
              scopedSlots: _vm._u([
                {
                  key: "button",
                  fn: function () {
                    return [
                      _c(
                        "btn",
                        {
                          staticClass: "currentBranch__button",
                          nativeOn: {
                            click: function ($event) {
                              return _vm.goTo("contact-form")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t("branchSearch.detail.button.contactForm")
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            !_vm.isForCompanies
              ? _c("JobButtons", {
                  staticClass: "branchDetail__buttons",
                  attrs: {
                    context: _vm.context.pageContext,
                    identifier: ["branchJobButton", "branchApplyButton"],
                  },
                  on: { listClick: _vm.onListClick },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.currentBranch.content, function (item) {
        return _c("div", { staticClass: "branchDetail__content" }, [
          _c("h2", {
            staticClass: "branchDetail__headline",
            domProps: { innerHTML: _vm._s(item.title) },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "branchDetail__text",
            domProps: { innerHTML: _vm._s(item.text) },
          }),
        ])
      }),
      _vm._v(" "),
      _vm.isForCompanies
        ? _c(
            "div",
            { ref: "contact-form", staticClass: "branchDetail__contact" },
            [
              _c("h2", { staticClass: "branchDetail__headline" }, [
                _vm._v(
                  _vm._s(_vm.$t("branchSearch.detail.company.contactHeadline"))
                ),
              ]),
              _vm._v(" "),
              _c("contact", {
                attrs: {
                  context: _vm.pageContext,
                  email: _vm.currentBranch.email,
                  token: _vm.currentBranch.emailToken,
                  "additional-payload": { branchCode: _vm.currentBranch.code },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentBranch.teaserImages.length < 0
        ? _c(
            "div",
            { staticClass: "branchDetail__content" },
            [
              _c("image-slider", {
                staticClass: "branchDetail__slider",
                attrs: { images: _vm.currentBranch.teaserImages },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isForCompanies
        ? _c(
            "div",
            { staticClass: "branchDetail__jobs" },
            [
              _c("h2", { staticClass: "branchDetail__headlineJob" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("branchSearch.detail.job.headline", [
                      _vm.currentBranch.city,
                    ])
                  )
                ),
              ]),
              _vm._v(" "),
              _c("job-latest", {
                attrs: { context: _vm.context, branch: _vm.currentBranch },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.magazineArticles.length < 0
        ? _c("div", { staticClass: "branchDetail__magazine" }, [
            _c(
              "div",
              { staticClass: "branchDetail__inner" },
              _vm._l(_vm.magazineArticles, function (article) {
                return _c("magazine-list-item", {
                  key: article.title,
                  attrs: { article: article, small: true },
                })
              }),
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "branchDetail__news" },
        [
          _c("news-and-event-list", {
            attrs: { context: _vm.context, branch: _vm.currentBranch },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isForCompanies
        ? _c(
            "div",
            { ref: "contact-form", staticClass: "branchDetail__contact" },
            [
              _c("h2", { staticClass: "branchDetail__headline" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("branchSearch.detail.applicants.contactHeadline")
                  )
                ),
              ]),
              _vm._v(" "),
              _c("contact", {
                attrs: {
                  context: _vm.pageContext,
                  email: _vm.currentBranch.email,
                  token: _vm.currentBranch.emailToken,
                  "additional-payload": { branchCode: _vm.currentBranch.code },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }